import React, { useMemo, useState, useEffect } from "react";

import { wordPressApi } from "app/api/wordpress/wordPressApi";

import MarkerTooltip from "components/supportingPages/customerService/stores/MarkerTooltip/MarkerTooltip";
import SearchLocation from "components/supportingPages/customerService/stores/SearchLocation/SearchLocation";

import { MapMarker, MenuItemData, Store } from "ts/types";

import haversineDistance from "utilities/haversineDistance";

import SupportingPageLayout from "components/common/layouts/SupportingPageLayout/SupportingPageLayout";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import SpNav from "components/supportingPages/SpNav/SpNav";
import { StyledTypography } from "components/supportingPages/customerService/stores/SearchLocation/SearchLocation.Styled";
import { useMatch } from "react-router";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import {
	ContentWrapper,
	StyledClosestStores,
	StyledCountrySelection,
	StyledMap,
} from "./StoresPage.Styled";
import { useTranslation } from "react-i18next";
import useStores from "hooks/useStores";
import { GoogleMapsSdk } from "components/common/Google/GoogleAutocompleteTypes";
import { Geolocation, Position } from '@capacitor/geolocation';
import { cloneDeep } from "lodash";

const StoresPage = () => {
	const match = useMatch("/:locale/*");
	const uri = match?.params["*"] || "";

	const pageContent = wordPressApi.useGetPageContentByUriQuery(uri);
	const menuItems = wordPressApi.useGetMenuItemsBySlugQuery("footer");

	const { data, isLoading, getForCountries, orderByTitle } = useStores();

	// Searched location
	const [place, setPlace] = React.useState<google.maps.places.PlaceResult>();

	const [googleApi, setGoogleApi] = useState<{
		map: any;
		api: GoogleMapsSdk
	}>();

	const { t } = useTranslation();

	// Countries Selected
	const [countries, setCountries] = React.useState<string[] | null>(null);
	// User location
	const [position, setPosition] = React.useState<Position>();

	// Selected store is the store link clicked by the user in Your Closest Stores section
	const [selectedStore, setSelectedStore] = React.useState<Store>();

	// Remove selected store when user enters a location or selects a country
	React.useEffect(() => {
		if (place || countries) {
			setSelectedStore(undefined);
		}
	}, [countries, place]);

	// The callback for clicking a store link in Your Closest Stores section
	const onSelectStore = React.useCallback((store: Store) => {
		// Trigger side effects when the same store is selected
		setSelectedStore({ ...store });
	}, []);

	// const countryOptions = [
	// 	"Serbia",
	// 	"Bosnia and Herzegovina",
	// 	"Montenegro",
	// 	"Republic of Macedonia",
	// 	"Croatia",
	// ];

	const countryOptions = [
		{
			key: '/ba',
			value: 'Bosnia and Herzegovina'
		},
		{
			key: '/rs',
			value: 'Serbia'
		},
		{
			key: '/me',
			value: 'Montenegro'
		},
		{
			key: '/mk',
			value: 'Republic of Macedonia'
		},
		{
			key: '/hr',
			value: 'Croatia'
		},
		{
			key: '/al',
			value: 'Albania' 
		},
		{
			key: '/si',
			value: 'Slovenia' 
		},

	];

	// Stores filtered by country
	const storesByCountry = React.useMemo(() => {
		return getForCountries(countries || []);
	}, [countries, data]);

	const storesByTitle = React.useMemo(() => {
		return orderByTitle(storesByCountry);
	}, [orderByTitle, storesByCountry]);


	// Get user location
	// React.useEffect(() => {
	// 	Geolocation.getCurrentPosition().then((position) => {
	// 		setPosition(position);
	// 	})
	// }, []);

	// Function to get distance between store and given coordinates
	const getDistance = React.useCallback(
		(store: Store, location: { lat: number; lng: number }) => {
			const { storeLocation } = store.storeInformation;
			const storeCoords = {
				latitude: Number(storeLocation.latitude),
				longitude: Number(storeLocation.longitude),
			};
			const locationCoords = {
				latitude: location.lat,
				longitude: location.lng,
			};
			const distanceInKm = haversineDistance(storeCoords, locationCoords);
			return distanceInKm;
		},
		[],
	);

	// Stores sorted by distance (nearest first)
	const storesByDistance = React.useMemo(() => {
		const storesWithValidCoords = storesByCountry.filter(
			(store) =>
				!Number.isNaN(Number(store.storeInformation.storeLocation.latitude)) &&
				!Number.isNaN(Number(store.storeInformation.storeLocation.longitude)),
		);

		// Sort by distance from location entered
		const geometry = place?.geometry;
		const lat = geometry?.location?.lat();
		const lng = geometry?.location?.lng();

		if (lat && lng) {
			const searchedLocation = { lat, lng };

			return storesWithValidCoords?.sort(
				(a, b) =>
					getDistance(a, searchedLocation) - getDistance(b, searchedLocation),
			);
		}

		// Sort by distance from user location if no location is entered
		// if (position) {
		// 	const userLocation = {
		// 		lat: position.coords.latitude,
		// 		lng: position.coords.longitude,
		// 	};

		// return storesWithValidCoords?.sort(
		// 	(a, b) => getDistance(a, userLocation) - getDistance(b, userLocation),
		// );
		// }

		return storesWithValidCoords;
	}, [getDistance, place, position, storesByCountry]);

	// List of map markers
	const markers = React.useMemo(() => {
		const markersArr = storesByDistance.map((store) => {
			const { latitude, longitude } = store.storeInformation.storeLocation;

			const marker = {
				id: store.id,
				lat: Number(latitude),
				lng: Number(longitude),
				tooltipComponent: <MarkerTooltip store={store} />,
			} as MapMarker;

			return marker;
		});

		return markersArr.filter(
			(marker) => !Number.isNaN(marker?.lat) && !Number.isNaN(marker?.lng),
		);
	}, [storesByDistance]);

	// Map center
	const center = React.useMemo(() => {
		// Centers on selected store
		if (selectedStore) {
			const storeLocation = selectedStore.storeInformation.storeLocation;

			const nextCenter = {
				lat: Number(storeLocation.latitude),
				lng: Number(storeLocation.longitude),
			};

			return nextCenter;
		}
		// Centers on the nearest store
		if (storesByDistance.length) {
			const nearestStore = storesByDistance[0].storeInformation.storeLocation;

			const nextCenter = {
				lat: Number(nearestStore.latitude),
				lng: Number(nearestStore.longitude),
			};

			return nextCenter;
		}
	}, [selectedStore, storesByDistance]);

	const parentNavOption = useMemo(() => {
		const parentItem = menuItems.data?.find((menuItem: MenuItemData) => {
			if (menuItem.children.find((child) => child.path.includes(uri))) {
				return menuItem;
			}
		}) as MenuItemData;

		return parentItem;
	}, [menuItems.data]);

	const navOptions = useMemo(() => {
		return (
			parentNavOption?.children.map((child) => {
				return {
					label: child.label,
					value: child.path,
				};
			}) || []
		);
	}, [parentNavOption, menuItems.data]);

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageContent.data?.seo.title}
				description={pageContent.data?.seo.metaDesc}
				keywords={pageContent.data?.seo.metaKeywords}
				robots={getRobotsContent([
					pageContent.data?.seo.metaRobotsNofollow,
					'index'
				])}
			/>
			<SupportingPageLayout data={pageContent.data} loading={isLoading}>
				<SpNav
					className="nav"
					isLoading={menuItems.isLoading}
					title={parentNavOption?.label || ""}
					options={navOptions}
				/>
				<ContentWrapper className="stores-selector">
					<StyledTypography className="helper-text">
						{t("searchForYourLocalMonaStore")}
					</StyledTypography>
					{googleApi && (
						<SearchLocation
							apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
							googleApi={googleApi}
							onChange={(place: google.maps.places.PlaceResult | undefined) => {
								setPlace(place);
							}}
						/>
					)}
					<StyledCountrySelection
						autoSelect={true}
						options={countryOptions}
						onChange={(countries: string[]) => {
							setCountries(countries);
						}}
					/>
					<StyledMap markers={markers} center={center} onApiLoad={(map: any, api: GoogleMapsSdk) => setGoogleApi({
						map,
						api
					})} />
					<StyledClosestStores
						hasLocation={position !== undefined}
						stores={storesByTitle}
						onSelectStore={onSelectStore}
					/>
				</ContentWrapper>
			</SupportingPageLayout>
		</Page>
	);
};

export default StoresPage;
