import { useDispatch, useSelector } from "redux/hooks";

import { setShowAddToCartConformation } from "redux/UI/uiSlice";

import AccountProductCard from "components/ProductCard/AccountOrder/AccountProductCard";
import Button from "../../common/ui/Button/Button";
import ButtonWithIcon from "../../common/ui/Button/ButtonWithIcon/ButtonWithIcon";

import { PORTAL_MODAL } from "utilities/portals";

import { ReactComponent as Padlock } from "../../../assets/images/svg/Checkout Securely White.svg";

import { useTranslation } from "react-i18next";
import { setCartIsLoading, setLastAddedItem } from "redux/cart/cartSlice";
import PathTranslationKey from "utilities/paths";
import {
	AddToCartConformationWrapper,
	StyledModalBody,
} from "./AddToCartConfirmation.Styled";
import { useEffect } from "react";

const AddToCartConformation = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { lastItemAdded } = useSelector((state) => state.cart);

	const portalId = PORTAL_MODAL;

	// Reset the loading spinner once conformation has appeared
	useEffect(() => {
		dispatch(setCartIsLoading(false));
	  }, []);

	const handleClose = () => {
		dispatch(setShowAddToCartConformation(false));
		dispatch(setLastAddedItem(undefined));
	};

	return (
		<AddToCartConformationWrapper
			className="AddToCartConformationWrapper"
			id={"123"}
			options={{
				showCloseButton: true,
				portalId,
				overlay: true,
				closeOnOverlayClick: true,
			}}
			onClose={handleClose}
			title={
				<h3 className="modal-title">{t("addToBasketCofirmation_Title")}</h3>
			}
		>
			<StyledModalBody>
				{lastItemAdded && (
					<AccountProductCard
						triggeredFrom="CART"
						productDetails={lastItemAdded}
						isConformation
						bypassSkeleton
					/>
				)}
				<div>
					<div className="button-wrapper">
						<ButtonWithIcon
							link={{ to: t(PathTranslationKey.CHECKOUT).toString() }}
							icon={<Padlock />}
							variant={"primary_01"}
							fullWidth
							className="checkout-button"
							onClick={() => dispatch(setShowAddToCartConformation(false))}
						>
							{t("basket.checkout")}
						</ButtonWithIcon>

						<Button
							link={{ to: t(PathTranslationKey.CART).toString() }}
							variant="primary_03"
							fullWidth
							textLight
						>
							{t("view.cart")}
						</Button>
					</div>

					<Button
						className="continue-shopping"
						onClick={() => dispatch(setShowAddToCartConformation(false))}
					>
						{t("basket.continueShopping")}
					</Button>
				</div>
			</StyledModalBody>
		</AddToCartConformationWrapper>
	);
};

export default AddToCartConformation;

(AddToCartConformation as any).whyDidYouRender = false;
