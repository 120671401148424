/**
 * Determines the number of days the cart cookie will persist based on the user type.
 * 
 * - If the user is registered (has a customerId), the cookie lasts for 30 days.
 * - If the user is not registered (customerId is null or undefined), the cookie lasts for only 7 days.
 * 
 * @param customerId - The ID of the registered user (or undefined if the user is not logged in).
 * @returns The number of days the cookie will last.
 */
export const getCartCookieExpiration = (customerId?: number): number => {
	return customerId ? 30 : 7;
};
