import MonaLogoMobile from "assets/images/logos/Mona Logo Mobile.svg";
import MonaLogo from "assets/images/logos/Mona Logo.svg";
import MonaManLogoMobile from "assets/images/logos/MonaMan Logo Mobile.svg";
import MonaManLogo from "assets/images/logos/MonaMan Logo.svg";
import Link from "components/common/ui/Link/Link";
import useBrand, { Brand } from "hooks/useBrand";
import { useTranslation } from "react-i18next";
import { aboveMedium } from "utilities/variables/media";

const Logo = () => {
  const { t } = useTranslation();
  const { active } = useBrand();
  const link = active === Brand.Global ? "/": active === Brand.Men ? t("men_url")  : t("women_url");

  return (
    <div className="logo-wrapper">
      <Link to={link} className={"w-100"}>
        <picture className="logo">
          <source
            media={aboveMedium}
            srcSet={active === Brand.Men ? MonaManLogo : MonaLogo}
          />
          <img
            src={active === Brand.Men ? MonaManLogoMobile : MonaLogoMobile}
            alt="Mona Fashion"
          />
        </picture>
      </Link>
    </div>
  );
};

export default Logo;
