import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "redux/hooks";
import uuid from "react-uuid";

import { useLazyGetHomepageDataQuery } from "app/api/homepage/homepageApi";
import SEO from "components/common/ui/SEO/SEO";
import useBrand, { Brand } from "hooks/useBrand";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import { LandingPageWrapper } from "./LandingPage.Styled";
import LandingPageSkeleton from "./LandingPageSkeleton";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import HeroCarousel from "components/common/ui/HeroCarousel/HeroCarousel";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
	const { active, param } = useBrand();
	const { t } = useTranslation();

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [trigger, { data, isLoading }] = useLazyGetHomepageDataQuery();

	useEffect(() => {

		let homepageUrl = '/'
		if(active === Brand.Men){
			homepageUrl = t("url.homepage.men")
		} else if(active === Brand.Women){
			homepageUrl = t("url.homepage.women")
		}

		let uri = "/";

		uri =
			wordpressTemplates.find(
				(template) => template.uri === homepageUrl,
			)?.uri || uri;

		trigger(uri);
	}, [active, wordpressTemplates, param, trigger]);

	return (
		<Suspense fallback={<LoadingSpinner />}>
			<Page pageType={PageType.LANDING}>
				<SEO
					title={data?.seo?.title}
					description={data?.seo?.metaDesc}
					keywords={data?.seo?.metaKeywords}
					robots={getRobotsContent([
						data?.seo?.metaRobotsNofollow,
						'index'
					])}
				/>
				<LandingPageWrapper>
					{!isLoading && data?.homeHeroCarousel && (
						<HeroCarousel carouselData={data?.homeHeroCarousel} />
					)}

					{isLoading ? (
						<LandingPageSkeleton />
					) : (
						data?.homePageLayout?.map((layoutType: any) => (
							<ImageBanner {...layoutType} key={uuid()} isLoading={false} />
						))
					)}
				</LandingPageWrapper>
			</Page>
		</Suspense>
	);
};

export default LandingPage;
