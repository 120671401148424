import { getCartCookieExpiration } from "behavior/getCartCookieExpiration";
import Cookies from "js-cookie";
import { COOKIE_PRODUCT_GROUP } from "utilities/consts";

interface MeasurementType {
	size: {
		[key: number]: string;
	};
	halfSizes: {
		[key: number]: number;
	};
}

type CookieData = {
	sku: string;
	groupId: string;
};
export const measurementData771: MeasurementType = {
	size: {},
	halfSizes: {
		7: 7,
		7.5: 7.5,
		8: 8,
		8.5: 8.5,
		9: 9,
		9.5: 9.5,
		10: 10,
		10.5: 10.5,
		11: 11,
	},
};

export const measurementData431: MeasurementType = {
	size: {
		3: "S",
		4: "M",
		5: "L",
		6: "XL",
		7: "XXL",
	},
	halfSizes: {
		70: 7,
		75: 7.5,
		80: 8,
		85: 8.5,
		90: 9,
		95: 9.5,
		100: 10,
		105: 10.5,
		110: 11,
	},
};

export const measurementData471: MeasurementType = {
	size: {
		46: "XS",
		48: "S",
		50: "M",
		52: "L",
		54: "XL",
	},
	halfSizes: {},
};

export const measurementData451: MeasurementType = {
	size: {
		40: "S",
		41: "M",
		42: "L",
		43: "XL",
	},
	halfSizes: {},
};

export const measurementData45X: MeasurementType = {
	size: {
		36: "XS",
		38: "S",
		40: "M",
		42: "L",
		44: "XL",
	},
	halfSizes: {},
};

export const measurementData44X: MeasurementType = {
	size: {
		36: "XS",
		38: "S",
		40: "M",
		42: "L",
		44: "XL",
	},
	halfSizes: {},
};

export const measurementData43X: MeasurementType = {
	size: {
		36: "XS",
		38: "S",
		40: "M",
		42: "L",
		44: "XL",
	},
	halfSizes: {},
};

export const measurementData41X: MeasurementType = {
	size: {
		36: "XS",
		38: "S",
		40: "M",
		42: "L",
		44: "XL",
	},
	halfSizes: {},
};

export const measurementData442: MeasurementType = {
	size: {
		40: "S",
		41: "M",
		42: "L",
		43: "XL",
		44: "2XL",
		45: "3XL",

		48: "S",
		50: "M",
		52: "L",
		54: "XL",
		56: "2XL",
		58: "3XL",
	},
	halfSizes: {},
};

export const unitOfMeasure = (
	groupId: string | undefined,
	value: number | string,
) => {
	if (!groupId || groupId === undefined) {
		return value;
	}

	const measurementData: Record<string, MeasurementType> = {
		"431": measurementData431,
		"451": measurementData451,
		"471": measurementData471,
		"45X": measurementData45X,
		"44X": measurementData44X,
		"43X": measurementData43X,
		"41X": measurementData41X,
		"442": measurementData442,
		"771": measurementData771,
	};

	/* Check if product is in the display override list, if so return relevant value / value with measurement. If not return value as it is passed in. */
	const measurement: string = ["431", "471", "451", "45X", "44X", "43X", "41X", "442" ].includes(
		groupId,
	)
		? "size"
		: ["661", "662", "663", "664", "665", "761", "762", "763", "764"].includes(
				groupId,
		  )
		? "cm"
		: ["771", "641"].includes(groupId)
		? "halfSizes"
		: "";

	if (measurement === "") {
		return value;
	} else if (measurement === "size" || measurement === "halfSizes") {
		const convertedValue = measurementData[groupId]?.[measurement as keyof MeasurementType][value as any] 
		
		return convertedValue;		
	}
	return value + measurement;
};


export const addItemToCookie = (cookieData: { sku: string; groupId: string }, customerId: number) => {
	
	
	if (!cookieData.groupId)
		return

	const existingCookie = Cookies.get(COOKIE_PRODUCT_GROUP);

	let itemsArray = [];

	if (existingCookie) {
		try {
			itemsArray = JSON.parse(existingCookie);
		} catch (e) {
			console.error("Error parsing cookie data:", e);
		}
	}

	const itemExists = itemsArray.some(
		(item: CookieData) => item.sku === cookieData.sku,
	);

	if (!itemExists) itemsArray.push(cookieData);

	const expiresDay = getCartCookieExpiration(customerId)

	Cookies.set(COOKIE_PRODUCT_GROUP, JSON.stringify(itemsArray), {
		expires: expiresDay,
	});
};