import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { setBrand } from "redux/UI/uiSlice";
import { useDispatch, useSelector } from "redux/hooks";

import { remove as removeAccents } from "remove-accents";

export enum Brand {
	Global = "global",
	Women = "women",
	Men = "men",
}

type FunctionReturnType<S, A> = {
	active: S;
	param: A;
};

const useBrand = (): FunctionReturnType<string, any> => {
	const dispatch = useDispatch();
	const brand = useSelector((state) => state.ui.brand);
	const { pathname, search } = useLocation();

	const normalizeUrl = decodeURI(pathname + search).toLowerCase();

	const { t } = useTranslation();

	const translations = useMemo(() => {
		return {
			women: t("women_url").toLowerCase(),
			men: t("men_url").toLowerCase(),
			womenNoAccents: removeAccents(t("women_url")).toLowerCase(),
			menNoAccents: removeAccents(t("men_url")).toLowerCase(),
		};
	}, [t]);

	useEffect(() => {
		let active: string = Brand.Global as string;
		if (String(normalizeUrl) && normalizeUrl !== undefined && normalizeUrl !== null) {
			if (
				normalizeUrl.includes(translations.women) ||
				normalizeUrl.includes(translations.womenNoAccents)
			) {
				active = Brand.Women as string;
			} else if (
				normalizeUrl.includes(translations.men) ||
				normalizeUrl.includes(translations.menNoAccents)
			) {
				active = Brand.Men as string;
			}
		}

		if (brand !== active) {
			dispatch(setBrand(active));
		}
	}, [normalizeUrl, t]);

	return {
		active: brand as string,
		param: brand === Brand.Global ? "/" : translations[brand],
	};
};

export default useBrand;
